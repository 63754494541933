import type { Experiment } from 'experiment';
import type { EditorModel } from 'types/core';

import {
  POC_TEMPLATE_IDS,
  SITE_CREATION_TEMPLATE_ID,
  SITE_GENERATION_TEMPLATE_ID,
} from './constants';

// duplicated from '@wix/santa-editor-utils/siteCreation'
const isSiteCreationSectionsTemplate = (originalTemplateId: string) => {
  return originalTemplateId === SITE_CREATION_TEMPLATE_ID;
};
// duplicated from '@wix/santa-editor-utils/siteCreation'
export const isSiteGenerationTemplate = (originalTemplateId: string) => {
  return originalTemplateId === SITE_GENERATION_TEMPLATE_ID;
};

export function getIsSectionsExperienceEnabled({
  isInsideEditorX,
  isInsideAppStudio,
  experiment,
  editorModel,
}: {
  isInsideEditorX: boolean;
  isInsideAppStudio: boolean;
  experiment: Experiment;
  editorModel: EditorModel;
}): boolean | undefined {
  if (
    isInsideAppStudio ||
    isInsideEditorX ||
    experiment.isOpen('se_kill_sections')
  ) {
    return false;
  }

  const { originalTemplateId } = editorModel.siteHeader;

  if (
    editorModel.siteMetaData?.renderHints?.isClassicSectionsSite ||
    experiment.isOpen('se_sections') ||
    POC_TEMPLATE_IDS.has(originalTemplateId) ||
    isSiteGenerationTemplate(originalTemplateId) ||
    isSiteCreationSectionsTemplate(originalTemplateId)
  ) {
    return true;
  }

  // NOTE: there are cases when we are not 100% sure if it enabled or not, migration e.g.
  return undefined;
}
